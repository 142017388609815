import { QueryLoader } from 'components';
import { appTheme } from 'theme';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from 'react-i18next';
import Select, { Option } from 'react-select';
import FieldContainer from '../../components/Inputs/FormikFields/FieldContainer';
import { FieldProps } from 'formik';
import styled from 'styled-components';
import { checklistSchool, searchChecklistSchool } from 'types/searchChecklistSchool';

interface IProps {
    isMultiSelect?: boolean | false;
    onChange: (value: any) => void;
    initialValues? : Option[]
    siteId?: string;
    withSelectAll?: boolean;
}

const SEARCHSCHOOL_QUERY = loader('./searchSchool.gql');

/**
 *
 * @param props Dropdown that allows to select multiple Schools at the same time
 * @returns
 */
const DropdownSchools: FunctionComponent<FieldProps & IProps> = (props) => {
    const {  onChange, isMultiSelect, initialValues, siteId, withSelectAll } = props;
    const [schoolValues, setSchoolValues] = useState(initialValues);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const { t } = useTranslation();

    const customStyles = {
        clearIndicator: (prevStyle) => ({
            ...prevStyle,
            color: appTheme.color.common.red,
            ':hover': { color: appTheme.color.common.red },
            backgroundColor: appTheme.color.grey[0],
        }),
        multiValue: (base) => {
            return {
                ...base,
                backgroundColor: appTheme.color.grey[0],
            };
        },
        multiValueRemove: (base) => {
            return {
                ...base,
                backgroundColor: false,
                color: appTheme.color.common.blue,
                ':hover': { color: appTheme.color.common.blue },
                order: 1,
            };
        },
        multiValueLabel: (base) => {
            return {
                ...base,
                backgroundColor: false,
                order: 2,
            };
        },
        option: (base) => ({
            ...base,
            ':hover': { color: appTheme.color.common.blue, fontWeight: appTheme.typography.fontWeight.bold, backgroundColor : appTheme.color.grey[0] },
            backgroundColor: false,
            ...(withSelectAll && {':first-child': { borderBottom: `1px solid ${appTheme.color.grey[2]}` }})
        }),
        placeholder: (base) => ({
            ...base,
            color: appTheme.color.grey[5],
            fontSize: appTheme.typography.fontSizeS,
            fontWeight: appTheme.typography.fontWeight.bold,
        }),
        control: (base) => ({
            ...base,
            minHeight: 52,
        }),
        indicatorSeparator: (styles) => ({display:'none'}),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
            color : appTheme.color.common.navy
        })
    };

    let selectedOptions: Option[];

    return (
        <QueryLoader
            context={{ debounceKey: 'schools' }}
            query={SEARCHSCHOOL_QUERY}
            variables={{ siteId }}
            errorPolicy="all"
            renderWhileLoading
        >
            {({  data, refetch }: QueryResult<searchChecklistSchool>) => {
                let choices: checklistSchool[] = [];

                try {
                    choices = data.choices;

                    const dataToUse = choices;

                    selectedOptions = dataToUse.map((school) => {
                        return {
                            value: school.id,
                            label: school.label,
                        };
                    });

                    if (withSelectAll && !isAllSelected) {
                        selectedOptions.unshift({ label: t('component:select.selectAll'), value: 'ALL' });
                    }
                } catch (e) {
                    console.error(e);
                }

                return (
                    <FieldContainer {...props}>
                        <FieldWrapper>
                            <Select
                                onChange={(value) => {
                                    if (value && value.length < selectedOptions.length) {
                                        setIsAllSelected(false);
                                    }
                                    if (value && value.find((e) => e.value === 'ALL')) {
                                        const allOptionsSelected = [...selectedOptions];
                                        allOptionsSelected.shift();

                                        setSchoolValues(allOptionsSelected);
                                        onChange(allOptionsSelected);
                                        setIsAllSelected(true);
                                        return;
                                    }
                                    setSchoolValues(value);
                                    onChange(value);
                                }}
                                isMulti={isMultiSelect}
                                minMenuHeight={52}
                                value={schoolValues}
                                options={selectedOptions}
                                onInputChange={(inputValue: string) => {
                                    refetch({ search: `%${inputValue}%` });
                                }}
                                placeholder={t('page:checkList.adminList.schoolPlaceholder')}
                                closeMenuOnSelect={!isMultiSelect}
                                styles={customStyles}
                            />
                        </FieldWrapper>
                    </FieldContainer>
                );
            }}
        </QueryLoader>
    );
};

const FieldWrapper = styled.div`
    width: inherit;
`;
export default DropdownSchools;
