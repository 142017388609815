export enum LocalStorageKey {
    ACCESS_TOKEN = 'accessToken',
    HOLDING_ID = 'holding',
    CHECKLIST_SITE_ID = 'checkListSite',
    POS_ID = 'pos',
    PLANNED_OFFERS_PAGINATION_STATE = 'PlannedOffersPaginationState',
    CASH_SYSTEM_ID = 'cashSystem'
}

export function getItem(key: LocalStorageKey) {
    return localStorage.getItem(key);
}

export function setItem(key: LocalStorageKey, value: string): void {
    localStorage.setItem(key, value);
}

export function removeItem(key: LocalStorageKey) {
    localStorage.removeItem(key);
}
