import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { formatIdMenuSystem, getArticleFamilyOptions, ID_MENU_SYSTEM_SEPARATOR } from 'services/articleService';
import {
    SelectField,
    FileInputField,
    TextInputField,
    PriceInputField,
    TagField,
    ToggleSwitchField,
    SubmitButton,
    ErrorMessage,
    InfoLabel,
    Icon,
} from 'components';
import { PanelContent, PanelFooter } from 'components/Panel';
import { getGlobalArticle_article_Article as Article } from 'types/getGlobalArticle';
import { listArticleConcepts_list_edges_node_ArticleConcept as ArticleConcept } from 'types/listArticleConcepts';
import isEqual from 'lodash/isEqual';
import ApolloErrors from 'services/apollo/errors';
import styled from 'styled-components';
import { ImportationType } from 'types/globalTypes';

const FORM_ID = 'article_edit_add_form';

enum ERRORS {
    ID_MENU_SYSTEM_ALREADY_USED = 'error:idMenuSystem.ALREADY_USED',
    ELEMENT_ID_ALREADY_USED = 'error:idElement.ALREADY_USED',
}

type ErrorType = keyof typeof ERRORS;

const getSchema = (t: any) =>
    Yup.object().shape({
        label: Yup.string().required(t('app:error.required')),
        description: Yup.string().nullable(true),
        family: Yup.string().required(t('app:error.required')),
        idMenuSystem: Yup.number().nullable(true),
        idElement: Yup.number().nullable(true),
    });

interface IAddOrEditArticleFormProps {
    article?: any;
    concepts: ArticleConcept[];
    updateArticle: Function;
    onSuccess?: Function;
    testID?: string;
}

const AddOrEditArticleForm = ({ article, concepts, updateArticle, onSuccess, testID }: IAddOrEditArticleFormProps) => {
    const [error, setError] = useState('');
    const [errorKey, setErrorKey] = useState('');
    const [t] = useTranslation();
    const castedArticle = article as Article;

    const isEditing = !!article;
    const lastUseDate = !!article?.lastUse && new Date(article.lastUse);
    const lastUseDateFormated = lastUseDate ? lastUseDate.toLocaleDateString(undefined, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
    }) : null;

    return (
        <Formik
            isInitialValid={isEditing}
            validationSchema={getSchema(t)}
            initialValues={{
                // @ts-ignore
                isActive: false,
                // @ts-ignore
                image: {
                    id: '',
                },
                // @ts-ignore
                family: null,
                ...castedArticle,
                priceHint: castedArticle ? castedArticle.priceHint.amount : 0,
                idsArticleConcept:
                    castedArticle && castedArticle.articleConcepts
                        ? castedArticle.articleConcepts.map((concept) => concept.id)
                        : [],
                idMenuSystem:
                    castedArticle && castedArticle.idMenuSystem ? formatIdMenuSystem(castedArticle.idMenuSystem) : '',
                idElement: castedArticle && castedArticle.idElement
                    ? Number(castedArticle.idElement)
                    : null,
            }}
            onSubmit={async (values, { setSubmitting }) => {
                const { articleConcepts, image, ...articleValue } = values;
                await updateArticle({
                    variables: {
                        ...articleValue,
                        idMenuSystem: values.idMenuSystem
                            ? 'oscar' + ID_MENU_SYSTEM_SEPARATOR + values.idMenuSystem
                            : null,
                        idElement: values.idElement
                            ? Number(values.idElement)
                            : null,
                        priceHint: `${values.priceHint}`,
                        idImage: values.image ? values.image.id : null,
                    },
                })
                    .then(({ errors }: any) => {
                        if (errors) {
                            throw errors;
                        }
                        if (onSuccess) {
                            setError('');
                            setErrorKey('');
                            onSuccess();
                        }
                        setSubmitting(false);
                    })
                    .catch((errs: any) => {
                        const errors: string[] = ApolloErrors.errorsMessages(errs);
                        const errorKey: ErrorType = (errors[0] || '') as ErrorType;

                        setErrorKey(errorKey);
                        setError(t(ERRORS[errorKey] || errs.message) || '');

                        setSubmitting(false);
                        return;
                    });
            }}
        >
            {({ errors, isSubmitting, values, initialValues, isValid }) => {
                return (
                    <>
                        {/* @todo: move translation to schema file */}
                        <PanelContent>
                            {
                              // @ts-ignore
                              <Form 
                                id={FORM_ID}
                                {...(testID && {'data-test':`${testID}-form`})}
                              >
                                <Field
                                    label={
                                        values.isActive
                                            ? t('page:globalCatalog.edit.form.isActive')
                                            : t('page:globalCatalog.edit.form.inActive')
                                    }
                                    name="isActive"
                                    component={ToggleSwitchField}
                                    {...(testID && {testID:`${testID}-isActive`})}
                                />
                                <Field
                                    label={t('page:globalCatalog.edit.form.label')}
                                    name="label"
                                    component={TextInputField}
                                    {...(testID && {testID:`${testID}-label`})}
                                />
                                <Field
                                    label={t('page:globalCatalog.edit.form.description')}
                                    name="description"
                                    component={TextInputField}
                                    {...(testID && {testID:`${testID}-description`})}
                                />
                                <Field
                                    label={t('page:globalCatalog.edit.form.family')}
                                    name="family"
                                    data={getArticleFamilyOptions(false)}
                                    component={SelectField}
                                    fullWidth
                                    {...(testID && {testID:`${testID}-family`})}
                                />
                                <Field
                                    label={t('page:globalCatalog.edit.form.idElement')}
                                    name="idElement"
                                    component={TextInputField}
                                    {...(testID && {testID:`${testID}-idElement`})}
                                />
                                {error && errorKey === 'ELEMENT_ID_ALREADY_USED' ? (
                                    <ErrorMessagePadded>{error}</ErrorMessagePadded>
                                ) : null}
                                {isEditing && <OscarInfoWrapper>
                                    <OscarInfoItem>
                                        <InfoLabel inline name={t('page:globalCatalog.edit.form.fromOscar')} />
                                        <IconContainer>
                                            {!!(article.importationType === ImportationType.Oscar)
                                                ? <Icon.Check width={18} height={16} color="#5F7081" />
                                                : <Icon.Cross width={16} color="#5F7081" />}
                                        </IconContainer>
                                    </OscarInfoItem>
                                    {
                                        lastUseDateFormated && <OscarInfoItem>
                                            <InfoLabel inline name={t('page:globalCatalog.edit.form.lastUse')} />
                                            <InfoLabel inline fontWeight="bold" name={lastUseDateFormated} />
                                        </OscarInfoItem>
                                    }
                                </OscarInfoWrapper>}
                                <Field
                                    label={t('page:globalCatalog.edit.form.concept')}
                                    name="idsArticleConcept"
                                    options={concepts}
                                    component={TagField}
                                    {...(testID && {testID:`${testID}-idsArticleConcept`})}
                                />
                                <Field
                                    label={t('page:globalCatalog.edit.form.price')}
                                    name="priceHint"
                                    type="numeric"
                                    component={PriceInputField}
                                    {...(testID && {testID:`${testID}-priceHint`})}
                                />
                                <Field
                                    label={t('page:globalCatalog.edit.form.picture')}
                                    name="image"
                                    component={FileInputField}
                                    {...(testID && {testID:`${testID}-image`})}
                                />
                            </Form>
                          }
                        </PanelContent>
                        <PanelFooter
                          {...(testID && {'data-test':`${testID}-footer`})}
                        >
                            <SubmitButton
                                formHasNoChange={isEditing && isEqual(values, initialValues)}
                                form={FORM_ID}
                                disabled={
                                    !isValid ||
                                    (isEditing && isEqual(values, initialValues)) ||
                                    Object.entries(errors).length !== 0 ||
                                    isSubmitting
                                }
                                {...(testID && {testID:`${testID}-submit`})}
                            />
                        </PanelFooter>
                    </>
                );
            }}
        </Formik>
    );
};

const ErrorMessagePadded = styled(ErrorMessage)`
    margin: 0 0 20px;
`;

const OscarInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1.5rem;
    width: 100%;
`;

const OscarInfoItem = styled.div`
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
        margin-right: 3rem;
    }
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default AddOrEditArticleForm;
