import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { Option } from 'react-select';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
    SelectField,
    TextInputField,
    SubmitButton,
    ErrorMessage,
    Title,
    Icon,
} from 'components';
import { PanelContent, PanelFooter } from 'components/Panel';
import isEqual from 'lodash/isEqual';
import ApolloErrors from 'services/apollo/errors';
import styled from 'styled-components';
import { CheckListUserRole } from 'types/globalTypes';
import { FieldWrapper } from '../AdminPage.style';
import DropdownSchools from 'feature/DropdownSchools';
import { checklistSchool, checklistUser , IChecklistSchoolsList} from 'types/listChecklistUsers';

const FORM_ID = 'checkListUser_edit_add_form';

enum ERRORS {
    ALREADY_EXISTS = 'error:email.ALREADY_TAKEN',
}

type ErrorType = keyof typeof ERRORS;

const getSchema = (t: any) =>
    Yup.object().shape({
        lastName: Yup.string().required(t('app:error.required')),
        firstName: Yup.string().required(t('app:error.required')),
        idUserRole: Yup.string().required(t('app:error.required')),
        schools: Yup.array().of(
            Yup.object().shape({
                value: Yup.string().required(t('app:error.required')),
                label: Yup.string().required(t('app:error.required'))
            })
        ).min(1),
        email: Yup.string().required(t('app:error.required'))
            .email(t('app:error.validEmail'))
    });

interface IAddOrEditCheckListUserFormProps {
    user?: checklistUser | null;
    updateEPointageUser: Function;
    onSuccess?: Function;
    isCreation?: boolean;
    siteId?: string;
}

const AddOrEditCheckListUserForm = ({ user, updateEPointageUser, onSuccess, isCreation, siteId }: IAddOrEditCheckListUserFormProps) => {
    const [error, setError] = useState('');
    const [errorKey, setErrorKey] = useState('');
    const [t] = useTranslation();

    const isEditing = !!user;

    const checkListUserRoleFieldValues = Object.keys(CheckListUserRole).map((idUserRole) => {
        return {
            id: CheckListUserRole[idUserRole],
            label: i18next.t(`schema:checkListUser.admin.roles.${CheckListUserRole[idUserRole]}`)
        }
    });

    const checkListUserSchools = user ?
        user.schools.map(school => ({ label: school.schoolName, value: school.schoolId})) :
        [];

    return (
        <Formik
            isInitialValid={isEditing}
            validationSchema={getSchema(t)}
            validateOnBlur={false}
            validateOnChange={true}
            initialValues={{
                ...user,
                schools: checkListUserSchools,
            }}
            onSubmit={async (values, { setSubmitting }) => {
                const {id, firstName, lastName, idUserRole, schools, email} = values;
                const currentSchoolsList: IChecklistSchoolsList[] = schools ?? [];
                const formatedSchoolsList = currentSchoolsList.reduce((schoolList: checklistSchool[], school) => {
                    school.value !== "0" && schoolList.push({
                        schoolName: school.label,
                        schoolId: school.value,
                    });
                    return schoolList;
                }, []);

                const initialInput = {
                    userId: id,
                    firstName,
                    lastName,
                    idUserRole,
                    holdingId: siteId,
                    schools: formatedSchoolsList,
                };

                const input = isCreation ? {...initialInput, email} : initialInput;

                await updateEPointageUser({
                    variables: input,
                })
                    .then(({ errors }: any) => {
                        if (errors) {
                            throw errors;
                        }
                        if (onSuccess) {
                            setError('');
                            setErrorKey('');
                            onSuccess();
                        }
                        setSubmitting(false);
                    })
                    .catch((errs: any) => {
                        const errors: string[] = ApolloErrors.errorsMessages(errs);
                        const errorKey: ErrorType = (errors[0] || '') as ErrorType;

                        setErrorKey(errorKey);
                        setError(t(ERRORS[errorKey] || errs.message) || '');

                        setSubmitting(false);
                        return;
                    });
            }}
        >
            {({ errors, isSubmitting, values, initialValues, isValid, setFieldValue }) => {
                return (
                    <>
                        <PanelContent>
                            <Form id={FORM_ID}>
                                <Title
                                    mode="H3"
                                    value={t('page:admin.employees.general')}
                                    icon={<Icon.Cog />}
                                />
                                <Field
                                    label={t('schema:checkListUser.admin.lastName')}
                                    name="lastName"
                                    component={TextInputField}
                                />
                                <Field
                                    label={t('schema:checkListUser.admin.firstName')}
                                    name="firstName"
                                    component={TextInputField}
                                />
                                <Field
                                    label={t('schema:checkListUser.admin.email')}
                                    name="email"
                                    component={TextInputField}
                                    disabled={isEditing}
                                    onChange={({value}) => {
                                        setFieldValue && setFieldValue('email', value);
                                        setError('');
                                        setErrorKey('');
                                    }}
                                />
                                {error && errorKey === 'ALREADY_EXISTS' ? (
                                    <ErrorMessagePadded>{error}</ErrorMessagePadded>
                                ) : null}
                                <FieldWrapper>
                                    <Field
                                        label={t('schema:checkListUser.admin.schools')}
                                        name="holdingList"
                                        isMultiSelect
                                        component={DropdownSchools}
                                        onChange={(option: Option | Option[]) => {
                                            setFieldValue && setFieldValue('schools', option);
                                        }}
                                        initialValues={values.schools}
                                        overrideShowRequiredAsterisk
                                        siteId={siteId}
                                        withSelectAll
                                    />
                                </FieldWrapper>
                                <Field
                                    label={t('schema:checkListUser.admin.role')}
                                    name="idUserRole"
                                    component={SelectField}
                                    data={checkListUserRoleFieldValues}
                                    fullWidth
                                />
                            </Form>
                        </PanelContent>
                        <PanelFooter>
                            <SubmitButton
                                formHasNoChange={isEditing && isEqual(values, initialValues)}
                                form={FORM_ID}
                                disabled={
                                    !isValid ||
                                    (isEditing && isEqual(values, initialValues)) ||
                                    Object.entries(errors).length !== 0 ||
                                    isSubmitting
                                }
                            />
                        </PanelFooter>
                    </>
                );
            }}
        </Formik>
    );
};

const ErrorMessagePadded = styled(ErrorMessage)`
    margin: 0 0 20px;
`;

export default AddOrEditCheckListUserForm;
